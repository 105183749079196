<template>
  <div id="home" class="pt-0">
    <v-container grid-list-xl fluid class="pa-0">
      <v-row class="d-flex">
        <v-col cols="12">
          <account-table :items="accounts" @click="handleClick" />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import AccountTable from '@/views/accounts/partials/AccountTable'
import { mapActions, mapState } from 'vuex'
import UserService from '@/services/user'

export default {
  components: { AccountTable },
  data() {
    return {
      totals: {
        usdMarketValue: 0,
        usdUnrealizedGain_Loss: 0,
        usdTotalCost: 0
      }
    }
  },
  computed: {
    ...mapState('account', ['accounts'])
  },
  async mounted() {
    // Verified token
    try {
      await UserService.me()
    } catch (e) {
      console.log(e)
    }
  },
  methods: {
    ...mapActions('account', ['setAccount']),
    handleClick(account) {
      this.setAccount(account)
      this.$router.push({ name: 'Home' })
    }
  }
}
</script>
<style scoped>
.container {
  padding: 0;
}
</style>
